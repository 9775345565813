import { reportSentryError } from '../sentry'
import { STORAGE_PREFIX } from './constants'
import { localStorage } from './localStorage'
import { sessionStorage } from './sessionStorage'

export const localStorageHelper = (
  storageKey: string
): ReturnType<typeof localStorage> | undefined => {
  const storageKeyWithPrefix = `${STORAGE_PREFIX}${storageKey}`

  try {
    return localStorage(storageKeyWithPrefix)
  } catch {
    reportSentryError(`Storage type: localStorage not supported`)
    return undefined
  }
}

export const sessionStorageHelper = (
  storageKey: string
): ReturnType<typeof sessionStorage> | undefined => {
  const storageKeyWithPrefix = `${STORAGE_PREFIX}${storageKey}`

  try {
    return sessionStorage(storageKeyWithPrefix)
  } catch {
    reportSentryError(`Storage type: sessionStorage not supported`)
    return undefined
  }
}
