import { logWarn } from '../index'

type EventCallback<T> = (...args: [T?, T?]) => void

abstract class EventBus<EventNameType extends string, EventData> {
  events: Map<EventNameType, EventCallback<EventData>[]> = new Map()

  on(eventName?: EventNameType, callback?: EventCallback<EventData>): void {
    if (!eventName || !callback) {
      logWarn('Event name or callback is not provided')
    } else {
      if (!this.events.get(eventName)) {
        this.events.set(eventName, [])
      }
      this.events.get(eventName)?.push(callback)
    }
  }

  off(eventName?: EventNameType, callback?: EventCallback<EventData>): void {
    if (!eventName || !callback) {
      logWarn('Event name or callback is not provided')
    } else {
      const callbacks = this.events.get(eventName)
      if (callbacks) {
        this.events.set(
          eventName,
          callbacks.filter((cb) => cb !== callback)
        )
      }
    }
  }

  emit(eventName: EventNameType, data?: EventData): void {
    const callbacks = this.events.get(eventName)

    callbacks?.forEach((callback) => {
      if (typeof callback === 'function') {
        callback(data)
      }
    })
  }

  of(eventName?: EventNameType): EventCallback<EventData>[] {
    if (!eventName) {
      logWarn('Event name is not provided')
      throw new Error('Event name is not provided')
    }

    return this.events.get(eventName) || []
  }

  once(eventName?: EventNameType, callback?: EventCallback<EventData>): void {
    if (!eventName || !callback) {
      logWarn('Event name or callback is not provided')
    } else {
      const onceCallback: EventCallback<EventData> = (data) => {
        callback(data)
        this.off(eventName, onceCallback)
      }

      this.on(eventName, onceCallback)
    }
  }
}

export default EventBus
