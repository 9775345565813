import { nativeEnum, number, object, string } from 'zod'

import { InteroperabilityStatus } from './constants'

export const InteroperabilityOptionsSchema = object({
  country: string().optional(),
  currency: string(),
  paymentAmount: number().optional(),
})

/**
 * Interoperability context is critical to provide smooth customer journey.
 */
export const InteroperabilityContextSchema = object({
  /**
   * Providing the interoperabilityToken inside InteroperabilityContext should always be the goal during the integration
   * since it will prevent Interoperability.resolve() method from performing an extra API call to determine the interoperability status.
   */
  interoperabilityToken: string().optional(),
  /**
   * Providing merchant reference would be a fallback solution in case interoperabilityToken is missing.
   * Alongside with platform reference, it will be used to determine the interoperability status.
   */
  merchantReference: string().optional(),
  /**
   * Providing platform reference would be a fallback solution in case interoperabilityToken is missing.
   * Alongside with merchant reference, it will be used to determine the interoperability status.
   */
  platformReference: string().optional(),
})

export const InteroperabilityResponse = object({
  status: nativeEnum(InteroperabilityStatus),
  shoppingSessionId: string(),
  paymentConfirmationToken: string().optional(),
})

export const InteroperabilityApiBridgeSchema = {
  interoperabilityApiResolve: {
    data: object({
      country: string().optional(),
      currency: string(),
      payment_amount: number().optional(),
      merchant_reference: string().optional(),
      platform_reference: string().optional(),
      interoperability_token: string().optional(),
    }),
    response: object({
      status: string(),
      payment_confirmation_token: string().optional(),
      shopping_session_id: string().optional(),
    }),
  },
}
