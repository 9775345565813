import { KlarnaJS } from '@klarna/web-sdk-types'
import { ErrorCodes, ErrorTypes } from '@klarna-web-sdk/utils'
import { SDKConfig } from '@klarna-web-sdk/utils/src/types'
import { v4 as uuid } from 'uuid'

import { EShoppingStorageKeys } from './constants'
import { PublicPayload, sessionListenerFunc, ShoppingSession } from './types'
import { handleError } from './utils/errorHandlers'
import {
  createShoppingSession,
  fetchInteroperabilityToken,
  getShoppingSession,
  updateShoppingSession,
} from './utils/shoppingSessionRequests'
import { localBrowserStorage } from './utils/storage'

export class ShoppingSDK {
  // eslint-disable-next-line no-use-before-define
  static instance: ShoppingSDK
  static getInstance(sdkConfig?: SDKConfig) {
    if (ShoppingSDK.instance) {
      return ShoppingSDK.instance
    } else if (sdkConfig) {
      ShoppingSDK.instance = new ShoppingSDK(sdkConfig)
      return ShoppingSDK.instance
    } else {
      return undefined
    }
  }

  private shoppingSessionId: string | undefined
  private shoppingSession: ShoppingSession | undefined
  private listeners: Record<string, sessionListenerFunc> = {}
  private sdkConfig: SDKConfig

  constructor(sdkConfig: SDKConfig) {
    this.sdkConfig = sdkConfig

    if (sdkConfig?.shoppingSessionId) {
      this.shoppingSessionId = sdkConfig?.shoppingSessionId
      localBrowserStorage?.set(EShoppingStorageKeys.ShoppingSessionId, this.shoppingSessionId)
    } else {
      try {
        const sessionId = localBrowserStorage?.get(EShoppingStorageKeys.ShoppingSessionId)
        if (sessionId) this.shoppingSessionId = sessionId
      } catch (error) {
        handleError({
          type: ErrorTypes.TECHNICAL_ERROR,
          code: ErrorCodes.INTERNAL_ERROR,
          message: 'Unable to retrieve shopping session from local storage',
          originalError: error,
        })
      }
    }

    // retrieve shopping session from backend if exists
    if (this.shoppingSessionId) this.retrieveSessionFromService(this.shoppingSessionId)

    // set instance to be used globally
    ShoppingSDK.instance = this
  }

  private async resetSession() {
    this.shoppingSession = undefined
    this.shoppingSessionId = undefined
    localBrowserStorage?.remove(EShoppingStorageKeys.ShoppingSessionId)
    this.triggerListeners()
  }

  private async retrieveSessionFromService(shoppingSessionId: string) {
    try {
      this.shoppingSession = await getShoppingSession(shoppingSessionId)
      this.triggerListeners()
    } catch (error) {
      this.resetSession()
      handleError({
        type: ErrorTypes.TECHNICAL_ERROR,
        code: ErrorCodes.INTERNAL_ERROR,
        message: 'Shopping Session not found for provided shoppingSessionId',
        originalError: error,
      })
    }
  }

  private async createSessionInService(data: Partial<PublicPayload> = { supplementaryData: {} }) {
    try {
      this.shoppingSession = await createShoppingSession(data)
      this.shoppingSessionId = this.shoppingSession.shoppingSessionId
      localBrowserStorage?.set(EShoppingStorageKeys.ShoppingSessionId, this.shoppingSessionId)
      return this.shoppingSessionId
    } catch (error) {
      handleError({
        type: ErrorTypes.TECHNICAL_ERROR,
        code: ErrorCodes.INTERNAL_ERROR,
        message: 'Error creating shopping session',
        originalError: error,
      })
      return undefined
    }
  }

  private triggerListeners() {
    Object.values(this.listeners).forEach((listener) => {
      listener(this.shoppingSession)
    })
  }

  public async getInteroperabilityToken(): Promise<string | undefined> {
    // TODO: SAS-129: remove this check once we are live with release 5
    if (this.sdkConfig?.environment === 'production') {
      handleError({
        type: ErrorTypes.TECHNICAL_ERROR,
        code: ErrorCodes.INTERNAL_ERROR,
        message: 'Interoperability token is not available in production environment yet.',
      })
      return undefined
    }

    try {
      let shoppingSessionId = this.shoppingSessionId

      if (!shoppingSessionId) shoppingSessionId = await this.createSessionInService()
      if (!shoppingSessionId) return undefined

      const { interoperabilityToken } = await fetchInteroperabilityToken(shoppingSessionId)

      return interoperabilityToken
    } catch (error) {
      handleError({
        type: ErrorTypes.TECHNICAL_ERROR,
        code: ErrorCodes.INTERNAL_ERROR,
        message: 'Error while fetching interoperability token',
        originalError: error,
      })
      return undefined
    }
  }

  public async getId(
    {
      forceCreateSession,
      environments,
    }: {
      forceCreateSession: boolean
      environments?: SDKConfig['environment'][]
    } = { forceCreateSession: false }
  ): Promise<string | undefined> {
    if (this.shoppingSessionId) return this.shoppingSessionId
    else {
      if (!forceCreateSession) return undefined
      if (environments && !environments.includes(this.sdkConfig.environment)) return undefined

      try {
        await this.createSessionInService()
        return this.shoppingSessionId
      } catch (error) {
        handleError({
          type: ErrorTypes.TECHNICAL_ERROR,
          code: ErrorCodes.INTERNAL_ERROR,
          message: 'Error while fetching shopping session id',
          originalError: error,
        })
        return undefined
      }
    }
  }

  public addListener(listener: sessionListenerFunc) {
    const listenerId = uuid()
    this.listeners[listenerId] = listener
    return listenerId
  }

  public removeListener(listenerId: string) {
    delete this.listeners[listenerId]
  }

  public async refresh() {
    if (!this.shoppingSessionId) return
    await this.retrieveSessionFromService(this.shoppingSessionId)
  }

  public async userInteracted() {
    if (!this.shoppingSessionId) {
      try {
        await this.createSessionInService()
        this.triggerListeners()
      } catch (error) {
        handleError({
          type: ErrorTypes.TECHNICAL_ERROR,
          code: ErrorCodes.INTERNAL_ERROR,
          message: 'Error creating shopping session',
          originalError: error,
        })
      }
    }
  }

  public async resumeSession(shoppingSessionId: string) {
    this.retrieveSessionFromService(shoppingSessionId)
    this.shoppingSessionId = shoppingSessionId
    localBrowserStorage?.set(EShoppingStorageKeys.ShoppingSessionId, this.shoppingSessionId)
  }

  /**
   * @hidden
   */
  getPublicAPI(): KlarnaJS['Shopping'] {
    return {
      session: () => ({
        getShoppingSessionId: async () => {
          const id = await this.getId({ forceCreateSession: true })

          if (!id) {
            throw handleError(
              {
                type: ErrorTypes.TECHNICAL_ERROR,
                code: ErrorCodes.INTERNAL_ERROR,
                message: 'Error while fetching shopping session id',
              },
              {
                logToConsole: true,
              }
            )
          }

          return id
        },
        getInteroperabilityToken: async () => {
          const token = await this.getInteroperabilityToken()

          if (!token) {
            throw handleError(
              {
                type: ErrorTypes.TECHNICAL_ERROR,
                code: ErrorCodes.INTERNAL_ERROR,
                message: 'Error while fetching interoperability token',
              },
              {
                logToConsole: true,
              }
            )
          }

          return token
        },
        submit: async (consumerSessionPayload?: Partial<PublicPayload>) => {
          try {
            if (this.shoppingSessionId) {
              if (consumerSessionPayload?.supplementaryData !== undefined) {
                await updateShoppingSession(this.shoppingSessionId, consumerSessionPayload)
              }
            } else {
              await this.createSessionInService(consumerSessionPayload)
            }
          } catch (error) {
            throw handleError(
              {
                type: ErrorTypes.TECHNICAL_ERROR,
                code: ErrorCodes.INTERNAL_ERROR,
                message: 'Error while submitting shopping session',
                originalError: error,
              },
              {
                logToConsole: true,
              }
            )
          }
        },
      }),
    }
  }
}

export type ShoppingSDKPublicAPI = ReturnType<typeof ShoppingSDK.prototype.getPublicAPI>

export const useShoppingSession = () => {
  const ShoppingSdkInstance = ShoppingSDK.getInstance()
  if (!ShoppingSdkInstance) {
    throw new Error(
      'Shopping SDK is not initialized. Ensure your package is initialized after initialization of Shopping SDK in the main SDK constructor.'
    )
  }

  return {
    getId: ShoppingSdkInstance.getId.bind(ShoppingSdkInstance) as ShoppingSDK['getId'],
    addListener: ShoppingSdkInstance.addListener.bind(
      ShoppingSdkInstance
    ) as ShoppingSDK['addListener'],
    removeListener: ShoppingSdkInstance.removeListener.bind(
      ShoppingSdkInstance
    ) as ShoppingSDK['removeListener'],
    userInteracted: ShoppingSdkInstance.userInteracted.bind(
      ShoppingSdkInstance
    ) as ShoppingSDK['userInteracted'],
    refresh: ShoppingSdkInstance.refresh.bind(ShoppingSdkInstance) as ShoppingSDK['refresh'],
    getInteroperabilityToken: ShoppingSdkInstance.getInteroperabilityToken.bind(
      ShoppingSdkInstance
    ) as ShoppingSDK['getInteroperabilityToken'],
    resumeSession: ShoppingSdkInstance.resumeSession.bind(
      ShoppingSdkInstance
    ) as ShoppingSDK['resumeSession'],
  }

  // the following code can be used to disables shopping session for certain conditions - keeping it for reference
  return {
    getId: async (): Promise<string | undefined> => {
      return undefined
    },
    addListener: () => {
      return 'fakeListenerId'
    },
    removeListener: () => {},
    userInteracted: () => {},
    refresh: () => {},
    getInteroperabilityToken: async (): Promise<string | undefined> => {
      return undefined
    },
    resumeSession: () => {},
  }
}
