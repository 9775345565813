import { logError, logWarn } from '@klarna-web-sdk/utils/src/logger'

import { ValidationError } from '../../errors'

/* Check that amount is valid */
function validateAmount(key: string, purchaseAmountValue?: string) {
  const amountBasedPlacementTypes = [
    'credit-promotion-auto-size',
    'credit-promotion-badge',
    'checkout',
    'product',
    'cart',
    'payment-options',
    'payment-descriptors',
  ]
  if (amountBasedPlacementTypes.includes(key)) {
    if (purchaseAmountValue === undefined) {
      const errorMessage = `The data-purchase-amount is missing in ${key} placement. It is a required attribute. Please check https://docs.klarna.com/on-site-messaging/in-depth-knowledge/placements/`
      logError(errorMessage)
      throw new ValidationError(errorMessage)
    }
    if (Number.isNaN(parseFloat(purchaseAmountValue)) || parseFloat(purchaseAmountValue) <= 0) {
      const errorMessage = `The data-purchase-amount in ${key} placement is invalid. It should be a number >= 0. Please check https://docs.klarna.com/on-site-messaging/in-depth-knowledge/placements/`
      logError(errorMessage)
      throw new ValidationError(errorMessage)
    }
  }
  if (`${key}`.includes('credit')) {
    if (purchaseAmountValue === undefined) {
      logWarn('missing data-purchase-amount in klarna-placement')
      return false
    }

    if (Number.isNaN(parseFloat(purchaseAmountValue))) {
      logWarn('invalid purchase-amount in klarna-placement, it should be a number')
      return false
    }

    if (parseFloat(purchaseAmountValue) < 0) {
      logWarn('invalid purchase-amount in klarna-placement, it should be >= 0')
      return false
    }

    if (`${purchaseAmountValue}`.includes('.') || `${purchaseAmountValue}`.includes(',')) {
      // eslint-disable-next-line max-len
      logWarn(
        'The data-purchase-amount should be in minor units (e.g. $120.00 should be passed as 12000). Please check https://docs.klarna.com/on-site-messaging/in-depth-knowledge/placements/#attributes'
      )

      // we should allow amounts with .0, e.g. 3000.0 is valid, 3000.1 is not valid
      if (!isInteger(parseFloat(purchaseAmountValue))) return false
    }
  }

  return true
}

function isInteger(value: number): boolean {
  return typeof value === 'number' && isFinite(value) && Math.floor(value) === value
}

export default validateAmount
