/**
 * str = 'this is a Link. a ref link is <a href="http://Link.com">Link</a> '
 * exp = new RegExp(`\\bLink?\\b(?![^<]*?<\\/a>)`, 'gmi')
 * str.replace(exp, 'REPLACED')
 *
 * Output: 'this is a REPLACED. a ref REPLACED is <a href="http://Link.com">Link</a> '
 */

export default function (content: string, hyperlinks?: Record<'url' | 'text', string>[]) {
  if (hyperlinks && hyperlinks.length) {
    hyperlinks.forEach(
      (linkMap) =>
        (content = content.replace(
          // Info: searches for word in input(case-insensitive) and replaces, but ignore matches in <a>tag
          // eslint-disable-next-line security-node/non-literal-reg-expr
          new RegExp(`\\b${linkMap.text}?\\b(?![^<]*?<\\/a>)`, 'gmi'),
          `<a href="${linkMap.url}" taget="_blank" part="osm-link" class="link">$&</a>`
        ))
    )
  }

  return content
}
