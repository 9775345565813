import { getUserLocale } from '@klarna-web-sdk/utils/src/getUserLocale'
import { getRegionFromLocale } from '@klarna-web-sdk/utils/src/region'
import { Environment } from '@klarna-web-sdk/utils/src/types'

import { ASSETS_URL } from '../../config'
import createInterstitialToken from '../createInterstitialToken/createInterstitialToken'
import enhanceLinkWithClientInfo from '../enhanceLinkWithClientInfo/enhanceLinkWithClientInfo'

export default function createInterstitialUrl({
  locale = getUserLocale(),
  environment,
  bespokeId = 'payment-information-generic-interstitial',
  clientId,
}: {
  locale?: string
  environment?: string
  bespokeId?: string
  clientId: string
}) {
  const region = getRegionFromLocale(locale)
  const baseUrl = ASSETS_URL[environment as Environment][region]

  const token = createInterstitialToken(locale, bespokeId)

  let url = `${baseUrl}/learn-more/index.html?showButtons=true&showBackground=false`

  url = enhanceLinkWithClientInfo(url, clientId)

  return `${url}#${token}`
}
