import { ApiError } from '@klarna-web-sdk/backend-bridge'
import { v4 as uuid } from 'uuid'
import { ZodError } from 'zod'

import { detectBrowserName, TrackerClients, trackerFactory } from '../index'
import { ErrorResult } from '../types'
import { ErrorCodes } from './errorCodes'
import { ErrorTypes } from './errorTypes'

export class BaseError {
  public name: string = 'BaseError'
  public errorId: ErrorResult['errorId']
  public errorMessage: ErrorResult['errorMessage']
  public errorType: ErrorResult['errorType']
  public errorCode: ErrorResult['errorCode']
  public validationErrors?: ErrorResult['validationErrors']
  public originalError?: unknown

  constructor(
    name: string,
    type: ErrorTypes,
    code: ErrorCodes,
    message: string,
    originalError?: unknown
  ) {
    this.name = name
    this.errorId = uuid()
    this.errorMessage = message
    this.errorType = ErrorTypes[type]
    this.errorCode = code
    this.originalError = originalError

    // check for zod validation errors
    if (
      originalError instanceof ZodError ||
      (Array.isArray(originalError) && originalError.every((error) => error instanceof ZodError))
    ) {
      if (originalError instanceof ZodError) {
        this.validationErrors = originalError.issues.map((issue) => ({
          parameter: issue.path.join('.'),
          reason: issue.message,
        }))
      } else if (Array.isArray(originalError)) {
        this.validationErrors = originalError
          .map((error) =>
            error.issues.map((issue) => ({
              parameter: issue.path.join('.'),
              reason: issue.message,
            }))
          )
          .flat()
      }
      return // Ensure to exit further processing after handling Zod errors
    }

    // check for backend api error
    if (originalError instanceof ApiError && originalError.response) {
      const response = originalError.response
      this.errorType = response.error_type as ErrorTypes
      this.errorCode = response.error_code as ErrorCodes
      this.validationErrors = response.errors

      if (response.error_id) {
        this.errorId = response.error_id
      }
      if (response.error_message) {
        this.errorMessage = response.error_message
      }
    }
  }

  async logToConsole() {
    const CONSOLE_MESSAGE_PREFIX = `Klarna Web SDK: ${this.name}:`
    const logStyle = 'color: black; background-color: #FFB3C7; padding: 2px;'

    const logData = {
      ...this,
    }

    delete logData.originalError

    console.error(`%c${CONSOLE_MESSAGE_PREFIX} ${JSON.stringify(logData, null, 2)}`, logStyle)
  }

  async logToTracker() {
    const tracker = trackerFactory(TrackerClients.websdk)
    const browserName = detectBrowserName()

    tracker.event('metric_merchant_error', {
      errorId: this.errorId,
      errorCode: this.errorCode,
      errorType: this.errorType,
      message: this.errorMessage,
      validationErrors: this.validationErrors,
      browserName,
      originalError: this.originalError
        ? JSON.stringify(this.originalError, Object.getOwnPropertyNames(this.originalError), 2)
        : undefined,
    })
  }
}
