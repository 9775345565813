import { html } from 'lit'
import { unsafeHTML } from 'lit/directives/unsafe-html.js'

import addKlarnaLogo, { isKlarnaTextAvailableInAd } from '../helpers/addKlarnaLogo/addKlarnaLogo'
import styles from './style.scss'

interface TopStripPromotionProps {
  customStyles?: string
  theme?: string
  variation?: string
  mainText?: string
  ctaLabel?: string
  legalText?: string
  onLearnMoreClick: () => null
}

export default function topStripPromotionAutoSize({
  customStyles,
  theme,
  variation = 'auto-size',
  mainText,
  ctaLabel,
  legalText,
  onLearnMoreClick,
}: TopStripPromotionProps) {
  function addKlarnaIfNotAvailable(adText: string = '') {
    if (!isKlarnaTextAvailableInAd(adText)) adText += ' Klarna'
    return adText
  }

  return html`
    <style>
      ${styles}
    </style>
    ${html`${unsafeHTML(customStyles)}`}
    <div
      data-testid="${ctaLabel ? 'top-strip-promotion-auto-size' : ''}"
      class="container ${theme} ${variation}"
      part="osm-container"
    >
      <p part="osm-message">
        ${addKlarnaLogo(addKlarnaIfNotAvailable(mainText))}
        <button class="link" part="osm-cta" @click=${onLearnMoreClick}>${ctaLabel}</button>
      </p>
      ${legalText ? html`<p part="osm-legal" class="legal">${legalText}</p>` : ''}
    </div>
  `
}
