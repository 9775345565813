import { array, boolean, nativeEnum, number, object, string, union } from 'zod'

import {
  EffectiveUxModes,
  InteractionModes,
  PaymentRequestState,
  PaymentRequestStateReason,
} from './constants'

export enum ShippingCollection {
  SHIPPING_ADDRESS = 'SHIPPING_ADDRESS',
  SHIPPING_OPTION = 'SHIPPING_OPTION',
}

export enum ShippingTypeAttributes {
  SIGNATURE_REQUIRED = 'SIGNATURE_REQUIRED',
  IDENTIFICATION_REQUIRED = 'IDENTIFICATION_REQUIRED',
  CONTACTLESS_DELIVERY = 'CONTACTLESS_DELIVERY',
  LEAVE_AT_DOOR = 'LEAVE_AT_DOOR',
  LEAVE_AT_CURB = 'LEAVE_AT_CURB',
  LEAVE_WITH_NEIGHBOUR = 'LEAVE_WITH_NEIGHBOUR',
  EXPRESS = 'EXPRESS',
  TRACKED = 'TRACKED',
  UNTRACKED = 'UNTRACKED',
}

export enum ShippingType {
  TO_DOOR = 'TO_DOOR',
  TO_CURB = 'TO_CURB',
  TO_MAILBOX = 'TO_MAILBOX',
  PICKUP_BOX = 'PICKUP_BOX',
  PICKUP_POINT = 'PICKUP_POINT',
  PICKUP_STORE = 'PICKUP_STORE',
  PICKUP_WAREHOUSE = 'PICKUP_WAREHOUSE',
  DIGITAL_EMAIL = 'DIGITAL_EMAIL',
  DIGITAL_DOWNLOAD = 'DIGITAL_DOWNLOAD',
  DIGITAL_OTHER = 'DIGITAL_OTHER',
  PHYSICAL_OTHER = 'PHYSICAL_OTHER',
}

export enum CustomerProfileCollection {
  NAME = 'profile:name',
  EMAIL = 'profile:email',
  PHONE = 'profile:phone',
  LOCALE = 'profile:locale',
  BILLING_ADDRESS = 'profile:billing_address',
  COUNTRY = 'profile:country',
  NATIONAL_IDENTIFICATION = 'profile:national_identification',
  DATE_OF_BIRTH = 'profile:date_of_birth',
}

export const PaymentRequestStateZ = nativeEnum(PaymentRequestState)
export const ShippingTypeZ = nativeEnum(ShippingType)
export const ShippingTypeAttributesZ = nativeEnum(ShippingTypeAttributes)
export const EffectiveUxModesZ = nativeEnum(EffectiveUxModes)
export const PaymentRequestStateReasonZ = nativeEnum(PaymentRequestStateReason)
export const Currency = string().regex(/^[A-Z]{3}$/)
export const Country = string().regex(/^[A-Z]{2}$/)
export const InteractionModesZ = nativeEnum(InteractionModes)

export const Address = object({
  city: string(),
  country: Country,
  postalCode: string().optional(),
  region: string().optional(),
  streetAddress: string(),
  streetAddress2: string().optional(),
})

export const ShippingObject = object({
  address: Address,
  recipient: object({
    attention: string().optional(),
    email: string().optional(),
    familyName: string(),
    givenName: string(),
    phone: string().optional(),
  }),
  shippingOption: object({
    shippingType: ShippingTypeZ,
    shippingTypeAttributes: array(ShippingTypeAttributesZ).optional(),
    shippingCarrier: string().max(255).optional(),
  }).optional(),
  shippingReference: string().optional(),
})

export const Shipping = array(ShippingObject).optional()

export const Customer = object({
  address: Address.optional(),
  email: string().optional(),
  familyName: string().optional(),
  givenName: string().optional(),
  phone: string().optional(),
})

export const LineItem = object({
  imageUrl: string().optional(),
  name: string(),
  productIdentifier: string().optional(),
  productUrl: string().optional(),
  quantity: number(),
  reference: string().optional(),
  shippingReference: string().optional(),
  totalAmount: number(),
  totalTaxAmount: number().optional(),
  unitPrice: number().min(0).optional(),
})

export const PaymentRequestConfig = object({
  redirectUrl: string()
    .regex(
      /^https:\/\/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,64}\b(([-a-zA-Z0-9()!@:%_+.~#?&//=]*)(\{[-a-zA-Z0-9._]+\})?)*(&[-a-zA-Z0-9()!@:%_+.~#?&//=]*)*$/
    )
    .optional(),
  requestShippingData: array(nativeEnum(ShippingCollection)).optional(),
  requestCustomerProfile: array(nativeEnum(CustomerProfileCollection)).optional(),
  allowedShippingCountries: array(Country).optional(),
}).optional()

export const PaymentRequestData = object({
  customer: Customer.optional(),
  currency: Currency,
  merchantReference: string().optional(),
  paymentAmount: number(),
  lineItems: array(LineItem).optional(),
  paymentReference: string().optional(),
  interoperability: object({ interoperabilityToken: string() }).optional(),
  shipping: Shipping,
  config: PaymentRequestConfig,
})

export const PaymentRequestDataAllOptional = PaymentRequestData.extend({
  currency: Currency.optional(),
  paymentAmount: number().optional(),
})

export const PaymentRequestOptions = object({
  interactionMode: InteractionModesZ.optional(),
  opfClientVersion: string().optional(),
})

export const KlarnaUser = object({
  address: Address.optional(),
  customerId: string().optional(),
  dateOfBirth: string().optional(),
  email: string().optional(),
  emailVerified: boolean().optional(),
  familyName: string().optional(),
  givenName: string().optional(),
  locale: string().optional(),
  nationalIdentification: object({
    number: string().optional(),
    country: Country.optional(),
  }).optional(),
  phone: string().optional(),
  phoneVerified: boolean().optional(),
}).nullable()

export const StateContextSchema = object({
  distribution: object({
    url: string(),
  })
    .partial()
    .nullable(),
  shipping: object({
    recipient: object({
      givenName: string().min(1).max(99),
      familyName: string().min(1).max(99),
      email: string().email().optional(),
      phone: string().max(99).optional(),
      attention: string().min(1).max(99).optional(),
    }),
    address: Address.optional(),
    shippingOption: object({}).optional(),
    shippingReference: string().min(1).max(255).optional(),
  }).optional(),
  paymentConfirmationToken: string().nullable(),
  paymentAuthorizationId: string().nullable(),
  klarnaCustomer: object({
    customerProfile: KlarnaUser,
  }),
  userAccountLinking: object({
    userAccountLinkingRefreshToken: string(),
    userAccountLinkingIdToken: string(),
  }).nullable(),
})
  .partial()
  .nullable()

export const PaymentRequestResponse = object({
  expiresAt: string(),
  paymentRequest: PaymentRequestData.extend({
    config: object({
      redirectUrl: string().optional(),
    }).optional(),
  })
    .partial()
    .nullable(),
  paymentRequestId: string(),
  previousState: PaymentRequestStateZ,
  state: PaymentRequestStateZ,
  stateContext: StateContextSchema.optional(),
  stateReason: PaymentRequestStateReasonZ.optional(),
})

/**
 * Backend API related schemas
 */

const AddressAPI = object({
  city: string(),
  country: Country,
  postal_code: string().optional(),
  region: string().optional(),
  street_address: string(),
  street_address2: string().optional(),
})

export const ShippingOptionAPI = object({
  shipping_type: ShippingTypeZ,
  shipping_type_attributes: array(ShippingTypeAttributesZ).optional(),
  shipping_carrier: string().max(255).optional(),
})

const ShippingAPI = array(
  object({
    address: AddressAPI,
    recipient: object({
      attention: string().optional(),
      email: string().optional(),
      family_name: string(),
      given_name: string(),
      phone: string().optional(),
    }),
    shipping_option: ShippingOptionAPI.optional(),
    shipping_reference: string().optional(),
  })
).optional()

const KlarnaUserAPI = object({
  address: AddressAPI.optional(),
  customer_id: string().optional(),
  date_of_birth: string().optional(),
  email: string().optional(),
  email_verified: boolean().optional(),
  family_name: string().optional(),
  given_name: string().optional(),
  locale: string().optional(),
  national_identification: object({
    number: string().optional(),
    country: Country.optional(),
  }).optional(),
  phone: string().optional(),
  phone_verified: boolean().optional(),
}).nullable()

export const StateContextSchemaAPI = object({
  distribution: object({
    url: string(),
  })
    .partial()
    .nullable(),
  shipping: object({
    recipient: object({
      given_name: string().min(1).max(99),
      family_name: string().min(1).max(99),
      email: string().email().optional(),
      phone: string().max(99).optional(),
      attention: string().min(1).max(99).optional(),
    }),
    address: AddressAPI,
    shipping_option: object({}).optional(),
    shipping_reference: string().min(1).max(255).optional(),
  }).optional(),
  payment_confirmation_token: string().nullable(),
  payment_authorization_id: string().nullable(),
  klarna_customer: object({
    customer_profile: KlarnaUserAPI,
  }),
  user_account_linking: object({
    user_account_linking_refresh_token: string(),
    user_account_linking_id_token: string(),
  }).nullable(),
})
  .partial()
  .nullable()

const PaymentRequestDataAPI = object({
  customer: object({
    address: AddressAPI.optional(),
    email: string().optional(),
    family_name: string().optional(),
    given_name: string().optional(),
    phone: string().optional(),
  }).optional(),
  currency: Currency,
  merchant_reference: string().optional(),
  payment_amount: number(),
  line_items: array(
    object({
      image_url: string().optional(),
      name: string(),
      product_identifier: string().optional(),
      product_url: string().optional(),
      quantity: number(),
      reference: string().optional(),
      shipping_reference: string().optional(),
      total_amount: number(),
      total_tax_amount: number().optional(),
      unit_price: number().optional(),
    })
  ).optional(),
  payment_reference: string().optional(),
  shipping: ShippingAPI,
  config: object({
    redirect_url: string().optional(),
    request_shipping_data: array(nativeEnum(ShippingCollection)).optional(),
    request_customer_profile: array(string()).optional(),
    allowed_shipping_countries: array(string()).optional(),
    shopping_session_id: string().optional(),
  }).optional(),
  interoperability: object({
    interoperability_token: string(),
  }).optional(),
})

export const PaymentRequestBodyAPI = object({
  ...PaymentRequestDataAPI.shape,
  internal: object({
    effective_ux_mode: string().optional(),
    client_version: string().optional(),
  }),
})
const PaymentRequestBodyAllOptionalAPI = PaymentRequestBodyAPI.extend({
  config: object({
    redirect_url: string().optional(),
    request_shipping_data: array(nativeEnum(ShippingCollection)).optional(),
    request_customer_profile: array(string()).optional(),
    allowed_shipping_countries: array(string()).optional(),
    shopping_session_id: string().optional(),
  }).optional(),
  internal: object({
    effective_ux_mode: string().optional(),
  }).optional(),
  currency: string().optional(),
  payment_amount: number().optional(),
})

export const PaymentRequestResponseAPI = object({
  expires_at: string(),
  payment_request: PaymentRequestDataAPI.extend({
    config: object({
      redirect_url: string().optional(),
      request_shipping_data: array(nativeEnum(ShippingCollection)).optional(),
      request_customer_profile: array(nativeEnum(CustomerProfileCollection)).optional(),
      allowed_shipping_countries: array(Country).optional(),
      shopping_session_id: string().optional(),
    }).optional(),
  }).nullable(),
  payment_request_id: string(),
  previous_state: PaymentRequestStateZ,
  state: PaymentRequestStateZ,
  state_context: StateContextSchemaAPI,
  state_reason: PaymentRequestStateReasonZ,
})

export const AbortSignalResponseAPI = object({
  state: PaymentRequestStateZ,
})

export const PaymentApiBridgeSchema = {
  paymentApiSendAuthorizationRequest: {
    data: object({
      body: PaymentRequestBodyAPI,
      region: string(),
    }),
    response: PaymentRequestResponseAPI,
  },
  paymentApiPatchAuthorizationRequest: {
    data: object({
      id: string(),
      body: PaymentRequestBodyAllOptionalAPI,
      region: string(),
    }),
    response: PaymentRequestResponseAPI,
  },
  paymentApiGetAuthorizationRequest: {
    data: object({
      id: string(),
      region: string(),
    }),
    response: PaymentRequestResponseAPI,
  },
  paymentApiCancelPaymentRequest: {
    data: object({
      id: string(),
      region: string(),
    }),
    response: PaymentRequestResponseAPI,
  },
  paymentApiSendAbortSignal: {
    data: object({
      id: string(),
      region: string(),
    }),
    response: AbortSignalResponseAPI,
  },
}

export const CanMakePaymentOptionsSchema = object({
  currency: Currency,
  country: Country,
  paymentAmount: number().optional(),
})

export const ShippingAddressChangeResponseSchema = object({
  paymentAmount: number().optional(),
  lineItems: array(
    object({
      name: string(),
      quantity: number(),
      totalAmount: number(),
      totalTaxAmount: number().optional(),
      unitPrice: number().min(0).optional(),
    })
  ).optional(),
  selectedShippingOptionReference: string().optional(),
  shippingOptions: array(
    object({
      shippingOptionReference: string(),
      amount: number(),
      displayName: string(),
      description: string(),
      shippingType: ShippingTypeZ.optional(),
    })
  ),
}).or(
  object({
    rejectionReason: string(),
  })
)

export const ShippingOptionSelectResponseSchema = union([
  object({
    paymentAmount: number(),
    lineItems: array(
      object({
        name: string(),
        quantity: number(),
        totalAmount: number(),
        totalTaxAmount: number().optional(),
        unitPrice: number().min(0).optional(),
      })
    ).optional(),
    shippingOptions: array(
      object({
        shippingOptionReference: string(),
        amount: number(),
        displayName: string(),
        description: string(),
        shippingType: ShippingTypeZ.optional(),
      })
    ).optional(),
  }),
  object({
    rejectionReason: string(),
  }),
])
