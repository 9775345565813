const allowedOrigins = [
  'https://s3.int.klarna.net',
  'https://js.klarna.com',
  'https://x.klarnacdn.net',
  'https://x.nonprod.us1.js.klarna.net',
  'https://osm.klarnaservices.com',
  'https://eu-assets.playground.klarnaservices.com',
  'https://na-assets.playground.klarnaservices.com',
  'https://ap-assets.playground.klarnaservices.com',
  'https://oc-assets.playground.klarnaservices.com',
]

export const validateKlarnaOrigin = (origin: string) => {
  return allowedOrigins.some((uri) => origin.startsWith(uri))
}
