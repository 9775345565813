import { OnPagePopupModeResult, triggerOnPage } from '@klarna/flow-interaction-mode'
import { useShoppingSession } from '@klarna-web-sdk/shopping'
import { reportSentryError } from '@klarna-web-sdk/utils/src/sentry'

import { messagingEvents } from '../../events'

const interactionModeOptions = {
  overlayContent: {
    text: 'Prequalification ongoing',
    buttonLabel: 'Continue prequalification',
  },
  onPopupClose: () => {
    const { refresh } = useShoppingSession()
    refresh()
  },
}

export async function openPrequalificationPopup(
  e: PointerEvent,
  params: {
    ctaLink: string
    emit?: (event: messagingEvents, detail?: string | object) => void
  }
) {
  const { ctaLink, emit } = params

  if (!emit) {
    return
  }

  e.preventDefault()
  emit(messagingEvents.MODAL_OPENED)
  emit(messagingEvents.MODAL_OPENED_LEGACY)

  const iframeLink = ctaLink.replace(
    'showButtons=false&showBackground=true',
    'showButtons=true&showBackground=false&websdk=true'
  )
  let onPage: OnPagePopupModeResult | undefined
  try {
    onPage = triggerOnPage(iframeLink, interactionModeOptions)
  } catch (error) {
    reportSentryError(error)
  }
  return onPage
}

export async function parsePrequalificationUrl(ctaLink: string) {
  const url = new URL(ctaLink)
  let shoppingSessionId = url.searchParams.get('shopping_session_id') || undefined

  if (!shoppingSessionId) {
    const { getId } = useShoppingSession()
    shoppingSessionId = await getId({ forceCreateSession: true })
    if (shoppingSessionId) {
      url.searchParams.set('shopping_session_id', shoppingSessionId)
    }
  }

  return url.href
}
