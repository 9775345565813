import { isValidLanguage } from '../isValidLanguage'
import { isValidLocale } from '../isValidLocale'
import { getFallbackRegion } from '../region'
import { RegionalLocaleMapping } from './regionalLocaleMapping'

export const getUserLocale = ({ locale = '', win = window, allowFallback = true } = {}): string => {
  // To support accidental typos in data-locale attribute
  if (locale) {
    locale = locale.replace('_', '-')
  }

  const localeParts = locale.split('-')

  locale =
    localeParts.length > 1 ? `${localeParts[0].toLowerCase()}-${localeParts[1].toUpperCase()}` : ''

  if (isValidLocale(locale)) {
    return locale
  }

  // For some products like OSM is better to fall hard and do not
  // show anything rather than displaying something that may not follow regulations of country
  if (!allowFallback) throw new Error('Invalid locale provided and allowFallback is disabled.')

  const browserLocale = win.navigator?.language

  if (isValidLanguage(browserLocale)) {
    return browserLocale
  }

  const fallbackRegion = getFallbackRegion()
  return RegionalLocaleMapping[fallbackRegion]
}
