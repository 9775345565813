export enum ErrorCodes {
  UNAUTHORIZED = 'UNAUTHORIZED',
  RATE_LIMITED = 'RATE_LIMITED',
  NOT_FOUND = 'NOT_FOUND',
  RESOURCE_NOT_FOUND = 'RESOURCE_NOT_FOUND',
  RESOURCE_CONFLICT = 'RESOURCE_CONFLICT',
  OPERATION_FORBIDDEN = 'OPERATION_FORBIDDEN',
  RATE_LIMITED_CLIENT = 'RATE_LIMITED_CLIENT',
  VALIDATION_ERROR = 'VALIDATION_ERROR',
  INVALID_CONTENT_TYPE = 'INVALID_CONTENT_TYPE',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  TEMPORARY_UNAVAILABLE = 'TEMPORARY_UNAVAILABLE',
}
