import { logWarn } from '@klarna-web-sdk/utils/src/logger'

import { MessagePreference } from '../../types'

const ALLOWED_MESSAGE_PREFERENCES = [
  'klarna',
  'in-store',
  'subscription',
  'on-demand',
  'prequalification',
]

export const getMessagePreference = (dataset: DOMStringMap): MessagePreference => {
  const attribute = dataset.messagePreference?.toLowerCase() || 'klarna'
  if (!ALLOWED_MESSAGE_PREFERENCES.includes(attribute)) {
    logWarn(`data-message-preference can only be one of ${ALLOWED_MESSAGE_PREFERENCES}`)
    return 'klarna'
  }

  return attribute as MessagePreference
}
