import { ErrorCodes, ErrorTypes } from '@klarna-web-sdk/utils'

import { ShoppingError } from './shoppingError'

export const handleError = (
  props: {
    type: ErrorTypes
    code: ErrorCodes
    message: string
    originalError?: unknown
  },
  options?: {
    logToConsole?: boolean
  }
) => {
  const { type, code, message, originalError } = props
  const logToConsole = options?.logToConsole
  const error = new ShoppingError(type, code, message, originalError)
  error.logToTracker()

  if (logToConsole) error.logToConsole()
  return error
}
