import { backendBridge } from '@klarna-web-sdk/backend-bridge'
import {
  camelCaseToSnakeCaseMap,
  snakeCaseToCamelCaseMap,
} from '@klarna-web-sdk/utils/src/caseConverter'
import { getUserLocale } from '@klarna-web-sdk/utils/src/getUserLocale'
import { getRegion } from '@klarna-web-sdk/utils/src/region'

import { PublicPayload } from '../types'

export const createShoppingSession = async (data: Partial<PublicPayload> = {}) => {
  const _response = await backendBridge.call({
    method: 'shoppingApiCreateSession',
    data: {
      body: { supplementary_purchase_data: camelCaseToSnakeCaseMap(data).supplementary_data },
      region: getRegion({ locale: getUserLocale() }),
    },
  })

  return snakeCaseToCamelCaseMap(_response)
}

export const getShoppingSession = async (shoppingSessionId: string) => {
  const _response = await backendBridge.call({
    method: 'shoppingApiGetSession',
    data: {
      shoppingSessionId,
      region: getRegion({ locale: getUserLocale() }),
    },
  })
  return snakeCaseToCamelCaseMap(_response)
}

export const updateShoppingSession = async (
  shoppingSessionId: string,
  data: Partial<PublicPayload>
) => {
  const _response = await backendBridge.call({
    method: 'shoppingApiUpdateSession',
    data: {
      shoppingSessionId,
      body: { supplementary_purchase_data: camelCaseToSnakeCaseMap(data).supplementary_data },
      region: getRegion({ locale: getUserLocale() }),
    },
  })

  return snakeCaseToCamelCaseMap(_response)
}

export const fetchInteroperabilityToken = async (shoppingSessionId: string) => {
  const _response = await backendBridge.call({
    method: 'shoppingApiGetInteroperabilityToken',
    data: {
      shoppingSessionId,
    },
  })

  return snakeCaseToCamelCaseMap(_response)
}
