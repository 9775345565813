import { klarnaElements } from './constants'
import { logError, logWarn } from './logger'

export class CreateElement {
  private containerElement: HTMLElement | null
  htmlElement: HTMLElement
  id: string | undefined

  constructor(type: klarnaElements, params?: { id?: string }) {
    const htmlElement = document.createElement(type)
    const emptySpan = document.createElement('span')
    htmlElement.appendChild(emptySpan)

    if (params) {
      const { id, ...args } = params
      Object.assign(htmlElement.dataset, args)

      if (id) {
        this.id = id
        htmlElement.id = id
        htmlElement.removeAttribute('data-id')
      }
    }

    this.htmlElement = htmlElement
  }

  mount(container: HTMLElement | string): ReturnType<CreateElement['getPublicAPI']> {
    this.containerElement =
      typeof container === 'string' ? document.querySelector(container) : container
    if (!(this.containerElement instanceof HTMLElement)) {
      logError('Missing valid `container`')
      return this.getPublicAPI()
    }

    this.containerElement.appendChild(this.htmlElement)
    return this.getPublicAPI()
  }

  unmount(): ReturnType<CreateElement['getPublicAPI']> {
    if (!this.containerElement || !this.containerElement.hasChildNodes()) {
      logWarn('Element not mounted')
      return this.getPublicAPI()
    }

    this.containerElement.removeChild(this.htmlElement)
    return this.getPublicAPI()
  }

  getPublicAPI() {
    return {
      mount: this.mount.bind(this) as typeof this.mount,
      unmount: this.unmount.bind(this) as typeof this.unmount,
      htmlElement: this.htmlElement,
      id: this.id,
    }
  }
}
