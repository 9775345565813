import { klarnaPlacements } from '@klarna-web-sdk/utils/src/constants'

import addHyperlinks from '../addHyperlinks/addHyperlinks'
import createInterstitialUrl from '../createInterstitialUrl/createInterstitialUrl'
import { PlacementAttributeOptions, PlacementAttributes } from './types'

export interface NodeType {
  name?: string
  type?: string
  value?: string
  hyperlinks?: Record<'url' | 'text', string>[]
  label?: string
  url?: string
  info_page_faq_nodes?: string[]
  pm_group_nodes?: Array<{ faq: object }>
}

const getPlacementConfigs = (key: string) => {
  switch (key) {
    case klarnaPlacements.CUSTOM_TYPE_0:
    case klarnaPlacements.CUSTOM_TYPE_1:
    case klarnaPlacements.CREDIT_PROMOTION_AUTO_SIZE:
    case klarnaPlacements.CREDIT_PROMOTION_STANDARD:
    case klarnaPlacements.CREDIT_PROMOTION_SMALL:
      return {
        variationReplaceKey: 'credit-promotion-',
        requiredAttributes: [
          'mainText',
          'legalText',
          'ctaLabel',
          'ctaLink',
          'variation',
          'theme',
          'customStyles',
        ],
      }

    case klarnaPlacements.CHECKOUT:
      return {
        variationReplaceKey: 'credit-promotion-',
        requiredAttributes: [
          'mainText',
          'zeroInterestBadgeText',
          'ctaLabel',
          'ctaLink',
          'variation',
          'theme',
          'customStyles',
          'buyersProtectionLink',
          'buyersProtectionLabel',
        ],
      }

    case klarnaPlacements.CREDIT_PROMOTION_BADGE:
      return {
        variationReplaceKey: '',
        requiredAttributes: [
          'mainText',
          'legalText',
          'ctaLink',
          'ctaLabel',
          'theme',
          'customStyles',
        ],
      }

    case klarnaPlacements.PRODUCT:
    case klarnaPlacements.CART:
    case klarnaPlacements.HEADER:
      return {
        variationReplaceKey: '',
        requiredAttributes: ['mainText', 'legalText', 'ctaLink', 'ctaLabel', 'logoType', 'theme'],
      }

    case klarnaPlacements.TOP_STRIP_PROMOTION_BADGE:
      return {
        variationReplaceKey: '',
        requiredAttributes: [
          'mainText',
          'legalText',
          'ctaLabel',
          'ctaLink',
          'fixedDiscountAmount',
          'discountRate',
          'theme',
          'customStyles',
        ],
      }

    case klarnaPlacements.TOP_STRIP_PROMOTION_STANDARD:
    case klarnaPlacements.TOP_STRIP_PROMOTION_AUTO_SIZE:
      return {
        variationReplaceKey: 'top-strip-promotion-',
        requiredAttributes: [
          'mainText',
          'ctaLabel',
          'variation',
          'ctaLink',
          'theme',
          'customStyles',
        ],
      }
    case klarnaPlacements.HOMEPAGE_PROMOTION_WIDE:
    case klarnaPlacements.HOMEPAGE_PROMOTION_TALL:
    case klarnaPlacements.HOMEPAGE_PROMOTION_BOX:
      return {
        variationReplaceKey: '',
        requiredAttributes: ['textTitle', 'textSubtitle', 'locale', 'theme', 'ctaLink'],
      }

    case klarnaPlacements.INFO_PAGE:
      return {
        variationReplaceKey: '',
        requiredAttributes: [
          'infoPageMainTitle',
          'infoPageMainSubtitle',
          'infoPagePaymentSectionTitle',
          'infoPagePaymentSectionDescription',
          'infoPageJourneyTitle',
          'infoPageJourneyStepOne',
          'infoPageJourneyStepTwo',
          'infoPageJourneyStepThree',
          'infoPageTitleTertiaryFirstColumn',
          'infoPageContentTertiaryFirstColumn',
          'infoPageTitleTertiarySecondColumn',
          'infoPageContentTertiarySecondColumn',
          'infoPageTitleFirstColumn',
          'infoPageContentFirstColumn',
          'infoPageTitleSecondColumn',
          'infoPageContentSecondColumn',
          'infoPageFAQs',
          'infoPagePMGroups',
          'theme',
          'assetsUrl',
        ],
      }

    default:
      return { requiredAttributes: [], variationReplaceKey: '' }
  }
}

function addValuesToAttributes(nodes: NodeType[], options: PlacementAttributeOptions) {
  const { assetsUrl, customStyles, locale, theme } = options
  const attributes: PlacementAttributes = {
    mainText: '',
    legalText: '',
    textTitle: '',
    textInfo: '',
    discountRate: '',
    fixedDiscountAmount: '',
    textSubtitle: '',
    badge: '',
    ctaLabel: '',
    ctaLink: '',
    infoPageMainTitle: '',
    infoPageMainSubtitle: '',
    infoPagePaymentSectionTitle: '',
    infoPagePaymentSectionDescription: '',
    infoPageJourneyTitle: '',
    infoPageJourneyStepOne: '',
    infoPageJourneyStepTwo: '',
    infoPageJourneyStepThree: '',
    infoPageTitleTertiaryFirstColumn: '',
    infoPageContentTertiaryFirstColumn: '',
    infoPageTitleTertiarySecondColumn: '',
    infoPageContentTertiarySecondColumn: '',
    infoPageTitleFirstColumn: '',
    infoPageContentFirstColumn: '',
    infoPageTitleSecondColumn: '',
    infoPageContentSecondColumn: '',
    infoPageFAQs: [],
    infoPagePMGroups: [],
    assetsUrl,
    customStyles,
    locale,
    theme: theme === 'light' ? 'default' : theme,
    variation: '',
    logoType: options.logoType,
  }

  nodes?.forEach(
    ({ value, label, url, hyperlinks: links, name, info_page_faq_nodes, pm_group_nodes }) => {
      switch (name) {
        case 'TEXT_MAIN':
          attributes.mainText = value
          break
        case 'TEXT_LEGAL':
          attributes.legalText = value
          break
        case 'TEXT_INFO':
          attributes.textInfo = value
          break
        case 'TEXT_TITLE':
          attributes.textTitle = value
          break
        case 'TEXT_SUB_TITLE':
          attributes.textSubtitle = value
          break
        case 'TEXT_BADGE_DISCOUNT_AMOUNT':
          attributes.fixedDiscountAmount = value
          break
        case 'TEXT_BADGE_DISCOUNT_RATE':
          attributes.discountRate = value
          break
        case 'ZERO_INTEREST_BADGE_TEXT':
          attributes.zeroInterestBadgeText = value
          break
        case 'ACTION_OPEN_BUYERS_PROTECTION_LINK':
          attributes.buyersProtectionLink = url
          attributes.buyersProtectionLabel = label
          break
        case 'KLARNA_BADGE':
          attributes.badge = url
          break
        case 'ACTION_LEARN_MORE':
          attributes.ctaLabel = label
          attributes.ctaLink = url || createInterstitialUrl(options)
          break
        case 'CHECKOUT_USP':
          if (value) attributes[`checkoutUSPs`] = (attributes[`checkoutUSPs`] || []).concat([value])
          break
        case 'ACTION_CHECKOUT_LEARN_MORE':
          attributes.ctaLink = url
          if (value && label) attributes[`checkoutLearnMore`] = { value, label }
          break

        case 'TEXT_INFO_PAGE_MAIN_TITLE':
          if (value) attributes.infoPageMainTitle = addHyperlinks(value, links)
          break
        case 'TEXT_INFO_PAGE_MAIN_SUBTITLE':
          if (value) attributes.infoPageMainSubtitle = addHyperlinks(value, links)
          break
        case 'TEXT_INFO_PAGE_PAYMENT_SECTION_TITLE':
          if (value) attributes.infoPagePaymentSectionTitle = addHyperlinks(value, links)
          break
        case 'TEXT_INFO_PAGE_PAYMENT_SECTION_DESCRIPTION':
          if (value) attributes.infoPagePaymentSectionDescription = addHyperlinks(value, links)
          break
        case 'TEXT_INFO_JOURNEY_TITLE':
          if (value) attributes.infoPageJourneyTitle = addHyperlinks(value, links)
          break
        case 'TEXT_INFO_PAGE_JOURNEY_STEP_ONE':
          if (value) attributes.infoPageJourneyStepOne = addHyperlinks(value, links)
          break
        case 'TEXT_INFO_PAGE_JOURNEY_STEP_TWO':
          if (value) attributes.infoPageJourneyStepTwo = addHyperlinks(value, links)
          break
        case 'TEXT_INFO_PAGE_JOURNEY_STEP_THREE':
          if (value) attributes.infoPageJourneyStepThree = addHyperlinks(value, links)
          break
        case 'TEXT_INFO_PAGE_TITLE_TERTIARY_FIRST_COLUMN':
          if (value) attributes.infoPageTitleTertiaryFirstColumn = addHyperlinks(value, links)
          break
        case 'TEXT_INFO_PAGE_CONTENT_TERTIARY_FIRST_COLUMN':
          if (value) attributes.infoPageContentTertiaryFirstColumn = addHyperlinks(value, links)
          break
        case 'TEXT_INFO_PAGE_TITLE_TERTIARY_SECOND_COLUMN':
          if (value) attributes.infoPageTitleTertiarySecondColumn = addHyperlinks(value, links)
          break
        case 'TEXT_INFO_PAGE_CONTENT_TERTIARY_SECOND_COLUMN':
          if (value) attributes.infoPageContentTertiarySecondColumn = addHyperlinks(value, links)
          break
        case 'TEXT_INFO_PAGE_TITLE_FIRST_COLUMN':
          if (value) attributes.infoPageTitleFirstColumn = addHyperlinks(value, links)
          break
        case 'TEXT_INFO_PAGE_CONTENT_FIRST_COLUMN':
          if (value) attributes.infoPageContentFirstColumn = addHyperlinks(value, links)
          break
        case 'TEXT_INFO_PAGE_TITLE_SECOND_COLUMN':
          if (value) attributes.infoPageTitleSecondColumn = addHyperlinks(value, links)
          break
        case 'TEXT_INFO_PAGE_CONTENT_SECOND_COLUMN':
          if (value) attributes.infoPageContentSecondColumn = addHyperlinks(value, links)
          break
        case 'TEXT_INFO_PAGE_FAQ_DEFAULT':
          attributes.infoPageFAQs = info_page_faq_nodes
          break
        case 'TEXT_INFO_PAGE_PAYMENT_OPTIONS':
          attributes.infoPagePMGroups = pm_group_nodes
          break
        /**
         * End:Info-page
         */
        default:
          break
      }
    }
  )

  return attributes
}

export default function getPlacementAttributes(
  nodes: NodeType[] = [],
  options: PlacementAttributeOptions
): PlacementAttributes {
  const allAttributes = addValuesToAttributes(nodes, options)
  const { requiredAttributes, variationReplaceKey } = getPlacementConfigs(options.key)

  // for credit-promotion small, auto-size we need to add a class to define the size of container differently. This is used to add the class dynamically.
  if (variationReplaceKey) {
    allAttributes.variation = options.key.replace(variationReplaceKey, '')
  }

  const placementAttributes = requiredAttributes.reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: allAttributes[curr as keyof PlacementAttributes],
    }),
    {}
  )

  return placementAttributes
}
